export enum SpinachAPIPath {
    User = '/user',
    UserBilling = '/user/billing',
    UserMetadata = '/user/metadata',

    AdminUser = '/admin/user/:userId',
    AdminUserHistories = '/admin/user/:userId/histories',
    AdminUserList = '/admin/user/list',
    AdminUserMeeting = '/admin/user/meeting/:botId',

    /** @NOTE - intentionally vague since these paths can be discovered with relative easy so as to not draw attn to it */
    RemoveUserRequest = '/rur',
    InvestigationApi = '/investigation',
    DisconnectUserCalendar = '/duc',
    ScheduleTestMeeting = '/stm',
    DeleteUserSessions = '/dus',

    MigrateSeriesToNewBot = '/migrate-series-to-bot',
    VerifyEmail = '/verify-email',
    VerifyGoogle = '/verify-google',
    VerifyGoogleWithCode = '/verify-google-code',
    VerifyCode = '/verify-code',
    Cognito = '/cognito',
    CognitoCheck = '/cognito-check',
    CognitoLogin = '/cognito-login',
    Session = '/session',
    LogOut = '/log-out',

    InternalToken = '/itoken',

    UserGoal = '/user-goal',

    ExperienceEvent = '/experience-event',

    Logging = '/logging',

    YTBHistory = '/ytb-history',

    PatchUserTicketSettings = '/user/ticket-settings',
    AddLegacySeriesToUser = '/user/series',
    AddUsersToLegacySeries = '/series/:seriesId/users',
    PatchSeries = '/series/:seriesId',

    UserCustomVocabulary = '/user/custom-vocabulary',
    UserCustomVocabularyMispellings = '/user/custom-vocabulary/:id/mispellings',

    RecentSignups = '/recent-signups',
    MeetingUUID = '/meeting-uuid',
    AskSpinach = '/ask-spinach',
    AskSpinachRealtimeConfig = '/ask-spinach/realtime/config',

    History = '/history',
    Series = '/series',
    AllSeries = '/series/all',
    GetSeries = '/series/:seriesId',
    RemoveUserFromSeries = '/series/:seriesId/users/:userId',
    RemoveAllUsersFromSeries = '/series/:seriesId',

    AccountRetention = '/account/retention',

    ZoomCollaborate = '/zoom-collaborate',
    ZoomCollaborateMeetingUUID = '/zoom-collaborate/:meetingUUID',

    // Google Auth
    GoogleCalendarAuth = '/google/auth-url',
    GoogleDriveAuth = '/google/drive/auth-url',
    GoogleDriveDisconnect = '/google/drive/disconnect',
    GoogleRedirect = '/google/redirect',
    GoogleFinished = '/google/finished',
    GoogleFailed = '/google/failed',
    GoogleFailedAccountMismatch = '/google/failed/account-mismatch',

    GoogleNewAuth = '/google/v2/auth',
    GoogleNewRedirect = '/google/v2/redirect',

    // Recall v2 integration endpoints
    GoogleRecallV2CalendarRedirect = '/google/recall/v2/calendar/redirect',
    GoogleRecallV2CalendarAuth = '/google/recall/v2/calendar/auth',

    // Google APIs
    // old & deprecated, will be deleted in a couple of weeks
    GoogleCalendarEvents = '/google/calendar/events',
    GoogleCalendarEventSuggestions = '/google/calendar/events/suggestions',
    UpdateScribeOnGoogleCalendarEvent = '/google/calendar/events/:iCalUid/scribe',
    UpdateScribeOnCalendarEvent = '/calendar/events/:iCalUid/scribe',
    /////////////////////////////
    GoogleCalendarUser = '/google/calendar/user',

    // calendar
    CalendarEvents = '/calendar/events',

    ScribeCalendarEvents = '/calendar/events/scribe',
    UpdateSchedulingAutomation = '/calendar/events/automated-scribe-settings',

    SendCalendarSuggestion = '/calendar/suggestion',
    CalendarEventSuggestions = '/calendar/events/suggestions',

    // Jira Auth
    JiraAuth = '/jira/auth-url',
    JiraRedirect = '/jira/redirect',
    JiraFinished = '/jira/finished',
    JiraFailed = '/jira/failed',
    JiraDisconnect = '/jira/disconnect',

    // Jira APIs
    JiraIssue = '/jira/issue/:issueId',
    JiraIssues = '/jira/issues',
    JiraSuggestions = '/jira/suggestions',
    JiraProjects = '/jira/projects',
    JiraUsersForProject = '/jira/projects/:projectId/users',
    JiraIssueSearch = '/jira/issue/search',
    JiraAccessibleResources = '/jira/accessible-resources',
    JiraRefreshTokens = '/jira/refresh-tokens',

    // Jira Facilitation APIs
    JiraRelevantIssues = '/jira/relevant-issues',

    // Confluence
    ConfluenceAccessibleResources = '/confluence/accessible-resources',
    ConfluenceSpace = '/confluence/space',
    FetchConfluenceSpace = 'confluence/space',
    ConfluenceRefreshTokens = '/confluence/refresh-tokens',

    // Patch Atlassian Site
    PatchAtlassianSite = '/atlassian/:atlassianSource/settings',

    // Asana Auth
    AsanaAuth = '/asana/auth-url',
    AsanaRedirect = '/asana/redirect',
    AsanaFinished = '/asana/finished',
    AsanaFailed = '/asana/failed',
    AsanaDisconnect = '/asana/disconnect',

    // Asana APIs
    AsanaIssue = '/asana/issue/:issueId',
    AsanaIssues = '/asana/issues',
    AsanaSuggestions = '/asana/suggestions',

    // Integration APIs
    IntegrationAuth = '/integration/:oauthProvider/auth-url',
    IntegrationRedirect = '/integration/:oauthProvider/redirect',
    IntegrationFinished = '/integration/:oauthProvider/finished',
    IntegrationFailed = '/integration/:oauthProvider/failed',
    IntegrationDisconnect = '/integration/:oauthProvider/disconnect',
    IntegrationSetApiKey = '/integration/:oauthProvider/api-key',

    // Linear Auth
    LinearAuth = '/linear/auth-url',
    LinearRedirect = '/linear/redirect',
    LinearFinished = '/linear/finished',
    LinearDisconnect = '/linear/disconnect',

    // ClickUp Auth
    ClickUpAuth = '/clickup/auth-url',
    ClickUpRedirect = '/clickup/redirect',
    ClickUpFinished = '/clickup/finished',
    ClickUpDisconnect = '/clickup/disconnect',

    // Stripe
    CreateCheckoutSession = '/checkout-session/create',
    CheckoutReturn = '/checkout-session/return',
    CreateCustomerPortalSession = '/customer-portal-session/create',

    // output
    UnsubscribeUserFromSeries = '/unsubscribe/:seriesId/:userId',

    EmailReferral = '/email-referral',

    ValidateSlackDomain = '/slack/domain/:slackTeamDomain',
    SlackHuddleBot = '/slack/huddles/bot',
    SlackChannels = '/slack/get-channels',
    SlackChannelsPopulation = '/slack/get-channels/population',
    SlackMembers = '/slack/get-members',
    SlackChannelSelection = '/slack/channel',
    SlackChannelJoin = '/slack/join-channel',
    SlackUser = '/slack/user',
    SlackUserByEmail = '/slack/user',
    SlackDefaultUserChannel = '/slack/user/channel',
    SlackUsers = '/slack/users',
    SlackSeries = '/slack/series/:seriesId',
    SlackMessagePermalink = '/slack/message/permalink',
    SlackMessageUpdate = '/slack/message/update',

    Redirect = '/redirect',

    NotionPages = '/notion/pages',
    NotionParentPages = '/notion/parent-pages',
    NotionUserParentPage = '/notion/user/page',

    SiteIntegrationSuccess = 'https://spinach.io/integration-success',
    SiteIntegrationFailure = 'https://spinach.io/integration-failed',
    SiteIntegrationFailureEmailMismatch = 'https://spinach.io/integration-failed-email-mismatch',
    SiteGoogleIntegrationFailureHasMicrosoftAccount = 'https://www.spinach.io/google-integration-failed-has-microsoft-account',

    ShareAIHistory = '/ai-history/:botId/share',
    AIHistory = '/ai-history',
    UsersWithAIHistoryAccess = '/ai-history/user-access',
    AITranscript = '/ai-transcript',
    Media = '/media',
    AsyncVideo = '/async-video',
    AiSummary = '/ai-summary',
    AiSummaryHtml = '/ai-summary-html',
    SearchData = '/search-data',
    EditSummary = '/edit-summary',
    EditSummaryActionItem = '/summary/action-item',
    SendEditedSummary = '/summary/send-edit',
    AIDrafts = '/ai-drafts',

    Facilitation = '/facilitation',
    FacilitationEvent = '/facilitation/event',

    MicrosoftHandleLoginWithCode = '/microsoft/code',
    MicrosoftHandleRecallV2LoginWithCode = '/microsoft/recall/v2/calendar/code',
    MicrosoftRecallV2Setup = '/microsoft/recall/v2/calendar',
    MicrosoftAuth = '/microsoft/auth-url',
    MicrosoftIntegrationAuthRedirect = '/microsoft/integration-redirect',
    MicrosoftFinished = '/microsoft/finished',
    MicrosoftFailed = '/microsoft/failed',
    MicrosoftFailedEmailMismatch = '/microsoft/failed-email-mismatch',
    MicrosoftCalendarDisconnect = '/microsoft/calendar/disconnect',
    AnonymousFeatureToggle = '/anonymous-feature-toggle',

    RecordGenerationAbTestResults = '/record-generation-ab-test-results',

    CreateTicket = '/ticket',
    CreateTicketForSource = '/ticket/:ticketSource',

    GetTicketProjects = '/:ticketSource/ticket/projects',
    GetTicketSubProjects = '/:ticketSource/ticket/projects/:projectId',

    SetupKnowledgeBaseSummary = `/:knowledgeBaseIntegration/summary-setup`,

    PatchTicketUserSettings = '/user/ticket/:ticketSource/settings',
    SendNotification = '/notifications/send',
    ScheduleNotifications = '/notifications/schedule',

    RecallZoomAppRedirect = '/recall-zoom-app-redirect',
    RecallZoomAppAuth = '/recall-zoom-app-auth',
    RecallZoomApp = '/recall-zoom-app',
    RecallZoomAppFinished = '/recall-zoom-app/finished',
    RecallZoomAppFailed = '/recall-zoom-app/failed',
    RecallWebhook = '/webhooks/recall',

    CelloAuthenticate = '/cello',

    UploadBrandImage = '/brand-image/upload',
    UploadAccountBrandImage = '/account/branded-image/upload',
    UploadAccountBrandImageRaw = '/account/branded-image/upload/raw',

    BrandedImage = '/branded-image',

    CombinedBrandedVideoBackgroundImage = '/branded-image/video-background-image',

    SpinachMediaBackgroundImage = '/spinach-media/video-background-image',

    SSOLoginAttemptExpired = '/sso-login-attempt-expired',

    AvatarStatus = '/avatar-status',
    AvatarWelcomeSpeechMessage = '/avatar-welcome-speech-message',
    ScheduledSignUpTracking = '/scheduled-sign-up-tracking',

    CustomerIoCleanup = '/script/customer-io-cleanup',
    AvatarClassifyTask = '/avatar/classify-task',
    AvatarClassifyTopicObjective = '/avatar/classify-topic-objective',
    AvatarGenerateTicketProposal = '/avatar/generate-ticket-proposal',
    AvatarSummarizeLiveTranscriptChunk = '/avatar/summarize-live-transcript-chunk',
    AvatarSummarizeLiveTranscriptChunkV2 = '/avatar/v2/summarize-live-transcript-chunk',
    AgentTopicObjective = '/agent/v1/topic-objective',

    VideoAgentSession = '/video-agent/session',
    VideoAgentForSeries = '/video-agent/series',

    ClaimHost = '/series/claim-host',

    ZapierChallenge = '/zapier/challenge',
    ZapierApiKey = '/zapier/api-key',
    ZapierWebhookSubscription = '/zapier/webhooks/:triggerType',
    ZapierList = '/zapier/list/:triggerType',
}
